<template>
  <v-dialog
    v-model="dialog"
    :width="$vuetify.breakpoint.smAndDown ? '100%' : '30%'"
  >
    <v-card>
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
        >{{ $t("select", { name: $tc("artists") }) }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="asignate-form"
        @submit.prevent="validateForm('asignate-form')"
      >
        <v-card-text>
          <v-row justify="center">
            <v-col class="py-0" cols="8">
              <label v-text="$tc('artist')"></label>
              <v-autocomplete
                outlined
                hide-details
                dense
                v-model="tattooer"
                :items="tattooers"
                :item-text="
                  (x) =>
                    `${x.tattooer.user.first_name} ${
                      x.tattooer.user.last_name ? x.tattooer.user.last_name : ''
                    }`
                "
                item-value="tattooer_id"
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('asignate-form.tattooer')"
                data-vv-name="tattooer"
              >
                <template slot="item" slot-scope="data">
                  <v-icon
                    v-if="data.item.tattooer.appointments_count === 0"
                    color="success"
                    class="mr-2"
                  >
                    mdi-check
                  </v-icon>
                  <v-icon v-else color="red" class="mr-2"> mdi-close </v-icon>
                  {{
                    `${data.item.tattooer.user.first_name} ${
                      data.item.tattooer.user.last_name
                        ? data.item.tattooer.user.last_name
                        : ""
                    }`
                  }}
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>

          <v-btn
            outlined
            @click="
              dialog = false;
              tattooer = appointment.tattooer_id;
            "
            style="height: 25px"
            elevation="0"
            class="mr-3"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            style="color: #363533; height: 25px; padding-top: 10px !important"
            elevation="0"
            type="submit"
            :disabled="tattooer === pre_tattooer"
          >
            <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: "" }) }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "TattooerCard",
  props: ["appointment"],
  data() {
    return {
      dialog: false,
      tattooers: [],
      tattooer: null,
      pre_tattooer: null,
    };
  },
  mounted() {},
  methods: {
    setTattooer(tattooer_id) {
      this.tattooer = tattooer_id;
      this.pre_tattooer = tattooer_id;
      this.fetchTattooers();
      this.dialog = true;
    },
    ...mapActions("studios", ["getStudioTattooersByDate"]),
    fetchTattooers() {
      console.log({
        studio_id: this.appointment.studio_id,
        appointment_id: this.appointment.id,
      });
      this.getStudioTattooersByDate({
        studio_id: this.appointment.studio_id,
        appointment_id: this.appointment.id,
      }).then((response) => {
        //console.log(response);
        //console.log(this.appointment.type);

        response = response.filter((t) => {
          let ta = t.tattooer.type_artist.find((ta) => {
            console.log(ta.name);
            console.log(ta.name === this.appointment.type);
            return ta.name === this.appointment.type;
          });
          if (ta)
            if (t.tattooer_id == this.pre_tattooer) return true;
            else {
              return !this.appointment.extra_tattooers
                .map((et) => et.id)
                .includes(t.tattooer_id);
            }

          return false;
        });
        this.tattooers = response;
      });
    },
    ...mapActions("appointments", ["addAppointmensTattooer"]),

    async validateForm(scope) {
      let result = await this.$validator.validateAll(scope);
      if (result) {
        console.log("save");
        this.addAppointmensTattooer({
          appointment_id: this.appointment.id,
          tattooer_id: this.tattooer,
          pre_tattooer_id: this.pre_tattooer,
        }).then((response) => {
          if (response.success) {
            this.$alert("Artista asignado correctamente");
            this.dialog = false;
            this.$emit("update");
          } else {
            this.$alert(this.$t(response.error));
          }
        });
      }
    },
  },
};
</script>



<style scoped lang="sass">
label
  //line-height: 1
  height: 25px
  display: block
.v-data-table
  td
    padding: 3px 8px !important
    &:first-child
      color: var(--v-gris1-base)
      min-width: 200px
      width: 200px
    &:last-child
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      max-width: 200px
h2
  font-weight: normal
  font-size: 20px
</style>